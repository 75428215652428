
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import HeaderBar from '@/components/shared/Header.vue'

export default defineComponent({
  name: 'Error',
  components: { HeaderBar },
  computed: {
    ...mapGetters(['fetchBinError'])
  },
  created() {
    if (!this.fetchBinError.status) {
      this.$router.push('/')
    }
  },
  unmounted() {
    this.setFetchBinError({})
  },
  methods: {
    ...mapMutations(['setFetchBinError'])
  }
})
